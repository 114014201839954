.rotator-navigation{
  position: absolute;
  top: 40%;
  transform: translateY(-40%);
  z-index: 10;
  display: flex;
  width: 105%;
  justify-content: space-between;
  align-items: center;
  //&.registration{
  //  width:105%;
  //}
  @include respond-to(medium-large){
    width: 65%;
  }

  .prev-arrow, .next-arrow{
    cursor: pointer;
    width: 0;
    height: 0;
    // border-top: 20px solid transparent;
    // border-bottom: 20px solid transparent;
    // position: absolute;
    background: transparent;
    transition: color .2s ease-in-out;
    font-size: 3rem;
    width: 2rem;
    height: 2rem;
    text-align: center;
    z-index: 25;
    transform: scaleX(.4);
    &.gray{
      color: $color-dark-blue;
      &:hover{
        color: $color-orange;
      }
    }
    &.white{
      color: $color-white;
      &:hover{
        color: $color-teal;
      }
    }
    &.teal{
      color: $color-teal;
      &:hover{
        color: $color-light-gray;
      }
    }
  }
  .prev-arrow{
    left: 0;
  }
  .next-arrow{
    right: 0;
  }
}

.registration-carousel{
  max-width: 35rem;
  .slide{
    img{
      width: 100%;
      height: auto;
    }

    p{
      padding-top: 1rem;
      font-weight: 600;
      text-align: center;
      color: $color-dark-blue;
      text-transform: uppercase;
    }
  }
  @include respond-to(small-phone){
    max-width: 20rem;
  }
  @include respond-to(medium){
    max-width: 35rem;
  }
  @include respond-to(medium-large){
    max-width: 25rem;
  }
}
.registration-dots ul.slick-dots {
  display: flex;
  justify-content: center;
  padding: 0;
  li {

    button {
      background-color: inherit;
      border: 1px solid $color-light-gray;
      border-radius: 50%;
      width: 10px;
      height: 10px;
      margin: 0 5px;
      cursor: pointer;
      padding: 0;
      font-size: 0;
      &:focus {
        outline: none;
      }
    }

    &.slick-active button {
      background-color: $color-teal;
      border-color: $color-teal;
    }
  }
}

.tracklab-carousel{
  max-width: 35rem;
  a {
    position: relative;
    color: $color-white;
    text-transform: uppercase;

    display: block;
    padding-bottom: .5rem;
    font-size: rem-calc(12);
    font-weight: 600;
    text-decoration: none;
    text-align: right;

    &::after {
      font-family: unset;
      content: '→';
      top: 0;
      transform: rotate(45deg);
      transform-origin: center;
    }

    @include respond-to(small-phone) {
      text-align: right;
    }
  }
  @include respond-to(small-phone){
    max-width: 20rem;
  }
  @include respond-to(medium){
    max-width: 35rem;
  }
  @include respond-to(medium-large){
    max-width: 25rem;
  }
}
.tracklab-dots ul.slick-dots {
  display: flex;
  justify-content: center;
  padding: 0;
  li {
    button {
      background-color: inherit;
      border: 1px solid $color-white;
      border-radius: 50%;
      width: 10px;
      height: 10px;
      margin: 0 5px;
      cursor: pointer;
      padding: 0;
      font-size: 0;
      &:focus {
        outline: none;
      }
    }

    &.slick-active button {
      background-color: $color-white;
      border-color: $color-white;
    }
  }
}
.inspiration-carousel, .registration-carousel {
  a {
    position: relative;
    color: $color-teal;
    text-transform: uppercase;

    display: block;
    padding-bottom: .5rem;
    font-size: rem-calc(12);
    font-weight: 600;
    text-decoration: none;
    text-align: right;

    &::after {
      font-family: unset;
      content: '→';
      top: 0;
      transform: rotate(45deg);
      transform-origin: center;
    }

    @include respond-to(small-phone) {
      text-align: right;
    }
  }
}
.inspiration-carousel{
  max-width: 35rem;
  .slide{
    img{
      width: 100%;
      height: auto;
    }

    p{
      padding-top: 1rem;
      font-weight: 600;
      text-align: center;
      color: $color-dark-blue;
      text-transform: uppercase;
    }
  }
  @include respond-to(small-phone){
    max-width: 20rem;
  }
  @include respond-to(medium){
    max-width: 35rem;
  }
  @include respond-to(medium-large){
    max-width: 25rem;
  }
}
.inspiration-dots ul.slick-dots {
  display: flex;
  justify-content: center;
  padding: 0;
  li {

    button {
      background-color: inherit;
      border: 1px solid $color-light-gray;
      border-radius: 50%;
      width: 10px;
      height: 10px;
      margin: 0 5px;
      cursor: pointer;
      padding: 0;
      font-size: 0;
      &:focus {
        outline: none;
      }
    }

    &.slick-active button {
      background-color: $color-teal;
      border-color: $color-teal;
    }
  }
}