//Media Query Mixin
@mixin respond-to($breakpoint) {
  @if $breakpoint == small-phone {
    @media only screen and (max-width: 600px) { @content; }
  } @else if $breakpoint == medium {
    @media only screen and (min-width: 601px) and (max-width: 768px) { @content; }
  } @else if $breakpoint == medium-large{
    @media only screen and (min-width: 769px) and (max-width: 950px) { @content; }
  } @else if $breakpoint == large {
    @media only screen and (min-width: 951px) and (max-width: 1300px) { @content; }
  } @else if $breakpoint == extra-large {
    @media only screen and (min-width: 1301px) { @content; }
  }
}

@function rem-calc($value){
  $remValue: calc($value / 16) + rem;
  @return $remValue;
}
