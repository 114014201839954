// ===============================================
// Layout
// Site: dlconcierge.com
// ===============================================

$maxWidth: 80rem;

@forward 'mixins';

body{
  margin: 0;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  -webkit-font-smoothing: antialiased;
  font-size:$base-font-size;
  font-family: $body-font-family;
  //@include respond-to(medium-large){
  //  $maxWidth: 50rem;
  //}

  nav{
    z-index:100;
    position: sticky;
    top: 0;
    &.sticky-nav-home{
      background-color: #5c5c5b;
      //background-color: #EBB712;
      transition: background-color .1s ease-in-out;
    }
    .nav-inner-container{
      //width: 100%;
      max-width: $maxWidth;
      display: flex;
      margin: 0 auto;
      padding: 2rem 0;
      @include respond-to(small-phone){
        //max-width: 40rem;
        //padding-left: 1rem;
        //padding-right: 1rem;
      }
      @include respond-to(medium){
        max-width: 50rem;
        padding-left: 1rem;
        padding-right: 1rem;
      }
      @include respond-to(medium-large){
        max-width: 60rem;
        padding-left: 1rem;
        padding-right: 1rem;
      }
      @include respond-to(large){
        max-width: 80rem;
        padding-left: 1rem;
        padding-right: 1rem;
      }
      .logo{
        @include respond-to(small-phone){
          padding-left: 1rem;
          img{
            width: 75%;
          }
        }
      }
    }
    menu{
      flex: 1;
      justify-content: flex-end;
      align-items: center;
      display: flex;
      list-style: none;
      color: $color-white;
      li{
        margin-left: 1rem;
        padding: 0 1rem;
        a{
          color: $color-white;
          text-decoration: none;
          font-family: 'AvenirNext-Bold', sans-serif;
          font-weight: 600;
          font-size: rem-calc(20);
          transition-property: color;
          transition-duration: 0.3s;
          transition-timing-function: ease-in-out;
          &:hover{
            color: $color-dark-blue;
            // text-decoration: underline solid black 2px;
            &:after {
              content: "";
              display: block;
              width: 1.5rem;
              padding: 0;
              border-bottom: 2px solid $color-dark-blue;
              transition: width 0.3s ease 0s, left 0.3s ease 0s;
            }
          }
        }
      }
      @include respond-to(small-phone){
        display: none;
      }
      @include respond-to(medium){
        display: none;
      }
    }
  }

  #header-home{
    display: flex;
    align-self: center;
    position: relative;
    z-index: 1;
    background-image:
            url('/assets/img/design/home-hero.jpg');
    background-size: cover;
    background-position: center center;
    width: 100%;
    top: -8rem;
    min-height: 34rem;

    @include respond-to(small-phone){
      min-height: 30rem;
    }

    video{
      position: absolute;
      right: 0;
      bottom: 0;
      min-width: 100%;
      min-height: 100%;
      width: 100%;
      object-fit: cover;
      height: 100%;
      z-index: -100;
      background-size: cover;
      overflow: hidden;
    }
    .head-container{
      max-width: $maxWidth;
      width: 100%;
      display: flex;
      margin: 0 auto;
      justify-content: center;
      @include respond-to(small-phone){
       padding: 8rem 0 2rem 0;
      }
      h1{
        font-size: rem-calc(56);
        align-self: center;
        font-family: 'AvenirNext-Bold', sans-serif;

        color: #FFFFFF;
        text-align: center;
        line-height: 72px;
        text-shadow: 4px 4px 8px rgba(0,0,0,0.50);
        max-width: 80%;
        @include respond-to(small-phone){
          font-size: rem-calc(46);
          line-height: 48px;
          max-width: 90%;
        }
      }
    }
  }



  #columns{
    width: 100%;
    margin-top: -8rem;

    .row-container{
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100%;
      padding: 2rem 0;

      &.white-full{
        .heading{
          width: 80%;
          font-family: AvenirNext-DemiBold, sans-serif;
          font-size: rem-calc(24);
          color: $color-dark;
          letter-spacing: 0;
          text-align: center;
          line-height: rem-calc(34);
          @include respond-to(small-phone){
            width: 100%;
          }
        }
        .blue-cta{
          display: block;
          padding-top: 2rem;
          font-family: AvenirNext-Bold, sans-serif;
          font-size: rem-calc(22);
          color: $color-dark-blue;
          letter-spacing: 0.33px;
          text-align: center;
          line-height: rem-calc(34);
          text-decoration: none;
        }


      }

      &.blue{
        //padding: unset;
        //padding-top: 1rem;
        background-color: $color-dark-blue;
        color: $color-white !important;
        font-family: AvenirNext-DemiBold, sans-serif;
        font-size: rem-calc(20);
        letter-spacing: 0.3px;
        line-height: 30px;
        margin-bottom: 0;

        .subhead{
          font-family: AvenirNext-Bold, sans-serif;
          font-size: rem-calc(24);
          color: #B6DBFE;
          letter-spacing: -0.5px;
          line-height: 58px;
          padding: 0;
          margin: 0 0 2rem 0;
          text-align: center;
          width: auto;
          //border-bottom: 2px solid $color-white;
          &:after{
            content: '';
            display: block;
            width: 50px;
            margin: 0 auto;
            border-bottom: 2px solid $color-white;
            @include respond-to(small-phone){
              width: auto;
            }
          }
        }
        .heading{
          margin: 0;
          font-family: AvenirNext-Bold, sans-serif;
          font-size: rem-calc(48);
          letter-spacing: -1px;
          line-height: rem-calc(58);
          //text-align: center;
        }
        .description{
          width: 80%;
          text-align: center;
          padding-top: 1.25rem;
          @include respond-to(small-phone){
            width: unset;
          }
        }

      }

      &.sweepstakes{
        margin-top: .5rem;
        background-color: #51C1B6;
        color: $color-white;
        .heading{
          font-family: AvenirNext-Bold, sans-serif;
          font-size: rem-calc(48);
          letter-spacing: -1px;
          text-align: center;
          line-height: rem-calc(58);
        }
        p{
          width: 80%;
          padding-top: 2rem;
          font-family: AvenirNext-Medium, sans-serif;
          font-size: rem-calc(20);
          color: $color-white;
          letter-spacing: .3px;
          line-height: 1.875rem;
          text-align: center;
        }
      }
      &.teal{
        background-color: #1D8290;
        color: $color-white;
        font-family: AvenirNext-DemiBold, sans-serif;
        font-size: rem-calc(20);
        letter-spacing: 0.3px;
        line-height: 30px;
        margin-bottom: 0;
        min-height: 20rem;

        .heading{
          margin: 0;
          font-family: AvenirNext-Bold, sans-serif;
          font-size: rem-calc(48);
          letter-spacing: -1px;
          line-height: rem-calc(58);
          @include respond-to(small-phone){
            font-size: rem-calc(36);
          }
        }
        .description{
          width: 80%;
          text-align: center;
          padding-top: 1.25rem;
          @include respond-to(small-phone){
            width: unset;
          }
        }
      }

      &.group-travel{
        .heading{
          font-family: AvenirNext-Bold, sans-serif;
          font-size: rem-calc(48);
          color: $color-dark;
          letter-spacing: -1px;
          text-align: center;
          line-height: rem-calc(58);
          padding-bottom: 2rem;
        }
        p {
          font-family: AvenirNext-Medium, sans-serif;
          font-size: rem-calc(18);
          color: $color-dark;
          letter-spacing: 0.3px;
          text-align: center;
          line-height: rem-calc(28);
          padding-bottom: 1.5rem;
          width: 80%;
          @include respond-to(small-phone){
            width: unset;
          }
        }
        .logo-holder{
          padding: 1rem 0;
          display: flex;
          width: 100%;
          justify-content: center;
          align-items: center;
          justify-items: unset;
          justify-content: space-evenly;
          @include respond-to(small-phone){
            flex-direction: column;
          }
          @include respond-to(medium){
            img{
              max-width: 10rem;
            }
          }
        }
      }

      &.benefits{
        background-color: #51C1B6;
        color: $color-white;
        .heading{
          font-family: AvenirNext-DemiBold, sans-serif;
          font-size: rem-calc(24);
          letter-spacing: 0.4px;
          text-align: center;
          line-height: rem-calc(32);
        }
        .icons{
          padding: 2rem 0;
          display: flex;
          width: 100%;
          //justify-content: center;
          justify-items: unset;
          justify-content: space-evenly;
            @include respond-to(small-phone){
                flex-direction: column;
              img{
                padding-bottom: unset;
              }
            }
          div{
            flex: 1;
            text-align: center;
            max-width: 25rem;
            @include respond-to(small-phone){
              max-width: unset;
              padding-bottom: 3rem;
              &:last-child{
                padding-bottom: unset;
              }
            }
            p{
              padding-top: 1.5rem;
              font-family: AvenirNext-Bold, sans-serif;
              font-size: rem-calc(18);
              color: #FFFFFF;
              letter-spacing: 0;
              line-height: rem-calc(24);
              @include respond-to(small-phone){
                padding-top: unset;
              }


            }
          }

        }
      }

      &.about-us{
        background-color: #F3F3F3;
        color: $color-dark;
        .heading{
          font-family: AvenirNext-Bold, sans-serif;
          font-size: rem-calc(48);
          letter-spacing: -1px;
          line-height: rem-calc(58);
        }
        p{
          padding-top: 1rem;
          font-family: AvenirNext-Medium, sans-serif;
          font-size: rem-calc(20);
          color: #040404;
          letter-spacing: 0.3px;
          line-height: rem-calc(30);
          text-align: center;
          width: 75%;
          @include respond-to(small-phone){
            width: unset;
          }
        }
        .dl-leadership-grid{
          padding-top: 3rem;
          display: grid;
          grid-template-columns: repeat(3, 1fr);
          gap: 2rem;
          max-width:80%;
          @include respond-to(small-phone){
            grid-template-columns: repeat(1, 1fr);
          }
          div {
            font-family: AvenirNext-Bold, sans-serif;
            text-align: center;
            img{
              width:100%;
              height: auto;
              @include respond-to(small-phone){
                padding-bottom: unset;
              }
            }
            p{
              padding: unset;
              font-size: rem-calc(16);
              width: unset;
              font-weight: 600;
            }
            h3{
              color: $color-teal;
              font-size: rem-calc(16);
              padding-top: 1rem;
              text-transform: uppercase;
            }
          }
        }
      }

      &.teal-bg{
        background-color: #3e808e;
        color: $color-white;
        font-family: AvenirNext-Bold, sans-serif;
        font-size: rem-calc(18);
        letter-spacing: 0;
        text-align: center;
        line-height: rem-calc(28);
        p{
          width: 75%;
          padding-bottom: 1rem;
          @include respond-to(small-phone){
            width: unset;
          }
        }
        .logos{
          display: grid;
          grid-template-columns: repeat(3, 1fr);
          gap: 2rem;
          width: 50%;
          padding-top: 2rem;
          @include respond-to(small-phone){
            grid-template-columns: repeat(1, 1fr);
            //width: 100%;
          }
          div{
            display: flex;
            background-color: #FBFBFB;
            width: 100%;
            align-items: center;
            justify-content: center;
            img{
              padding: 1rem 0;
            }
          }
        }

      }

      &.contact-us{
        //span{
        //  font-family: AvenirNext-Bold, sans-serif;
        //  font-size: rem-calc(24);
        //  color: $color-teal;
        //  letter-spacing: -0.5px;
        //  line-height: rem-calc(58);
        //  display: block;
        //  padding-bottom: 1rem;
        //  &:after {
        //    content: "";
        //    display: block;
        //    width: 2rem;
        //    padding: 0;
        //    border-top: 2px solid #ACACAC;
        //    transition: width 0.3s ease 0s, left 0.3s ease 0s;
        //  }
        //}
        //.tile-grid{
        //  display: grid;
        //  grid-template-columns: repeat(2, 1fr);
        //  gap: 1rem;
        //  justify-self: right;
        //
        //  img{
        //    width: 100%;
        //  }
        //  @include respond-to(small-phone){
        //    padding-top: 2rem;
        //    grid-template-columns: repeat(2, 1fr);
        //    img{
        //      padding-bottom: unset;
        //    }
        //  }
        //  @include respond-to(medium-large){
        //    grid-template-columns: repeat(1, 1fr);
        //
        //  }
        //}
        h2{
          font-family: AvenirNext-Bold, sans-serif;
          font-size: rem-calc(48);
          color: $color-dark;
          letter-spacing: -1px;
          line-height: rem-calc(58);
        }
        p{
          padding: 1rem 0;
        }
        .content{
          ul{
            font-family: AvenirNext-Medium, sans-serif;
            font-size: rem-calc(18);
            letter-spacing: 0.3px;
            //list-style: disc;
            padding-left: 20px;

            li {
              position: relative;
              color: $color-dark;
              padding: .5rem 0;
              display: flex;
              align-items: center; // Vertically centers the content

              &:before {
                content: '•';
                margin-right: 10px;
                color: $color-teal;
                font-size: 1.5em;
              }
            }
          }
        }

        .cta{
          padding-top: 2rem;
          a{
            color: $color-dark-blue;
            text-decoration: none;
            text-transform: uppercase;
            border: 2px solid $color-dark-blue;
            padding: 1rem 2rem;
            font-family: AvenirNext-Bold, sans-serif;
            font-size: rem-calc(20);
            letter-spacing: -0.42px;
            text-align: center;
            line-height: rem-calc(58);
            transition: .2s ease-in-out;
            &:hover{
              background-color: $color-dark-blue;
              color: $color-white;
            }
            @include respond-to(small-phone){
              display: block;
              padding: .5rem 1rem;
            }
          }

        }
      }
      &.white{
        background-color: $color-white;

        &.orange-border{
          border: 2px solid #EF5A34;
        }
        h3{
          text-transform: uppercase;
          color: $color-orange;
          font-size: rem-calc(16px);
          font-weight: 600;
          padding-top: 2rem;
        }
        ul{
          li{
            padding-top: .5rem;
            text-transform: uppercase;
          }
        }
        p{
          padding: 1rem 0;
        }

      }

      .row-inner{
        max-width: $maxWidth;
        width: 100%;
        display: flex;
        flex-direction: column;
        padding: 1rem 0;
        align-items: center;
        @include respond-to(small-phone){
          max-width: unset;
          width: unset;
          padding: 0 1rem;
          img{
            padding-bottom: 2rem;
            &:last-child {
              padding-bottom: 0;
            }
          }
        }
        @include respond-to(medium){
          max-width: 45rem;
          //padding: 0 1rem;
        }
        @include respond-to(medium-large){
          max-width: 55rem;
          //padding: 0 1rem;
        }
        @include respond-to(large){
          max-width: 80rem;
          //padding: 0 1rem;
        }


        &.two-up{
          display: flex;
          flex-direction: row;
          column-gap: 4rem;
          align-items: flex-start;
          @include respond-to(small-phone){
            flex-direction: column;
          }

          @include respond-to(medium) {

            flex-direction: column;
            img{
              padding: 2rem 0;
              margin: 0 auto;
            }
          }
          @include respond-to(medium-large){
            flex-direction: column;
            img{
              //padding: 2rem 0;
              margin: 0 auto;
            }
          }
          .heading{
            font-family: AvenirNext-Bold, sans-serif;
            font-size: rem-calc(48);
            color: $color-dark;
            letter-spacing: -1px;
            line-height: rem-calc(58);
          }

          p{
            padding-top: 1rem;
            font-family: AvenirNext-Medium, sans-serif;
            font-size: rem-calc(20);
            color: #040404;
            letter-spacing: 0.3px;
            line-height: rem-calc(30);
          }


          &.reverse{
            flex-direction: row-reverse;

            @include respond-to(small-phone){
              flex-direction: column;
            }
            @include respond-to(medium){
              flex-direction: column;
            }
            @include respond-to(medium-large){
              flex-direction: column;
            }
            &.white-text{
              .heading{
                color: $color-white;
              }
              p{
                color: $color-white;
              }
            }
            &.contact-exception{
              @include respond-to(medium-large){
                flex-direction: row-reverse !important;
              }
            }
          }

          div{
            flex:0 1 50%;
            &.carousel{
              display: flex;
              flex-direction: column;
              align-items: center;
              position: relative;
              @include respond-to(small-phone){
                padding: 2rem 0;
                width: 100%;
              }
              @include respond-to(medium){
                padding: 2rem 0;
                width: 100%;
              }
              @include respond-to(medium-large){
                padding: 2rem 0;
                width: 100%;
              }
              @include respond-to(large){
                padding: 2rem 0;
              }
            }
          }

        }

        &.blue-inner{
          background-image:
                  url('/assets/img/design/wwd-location.png'),
                  url('/assets/img/design/wwd-contract.png');
          background-position: left center, right bottom;
          background-repeat: no-repeat, no-repeat;
          background-size: auto auto; /* Ensure images are sized properly */
          min-height: 20rem;
        }
        &.teal-inner{
          width: 80%;
          justify-content: center;
          background-image:
                  url('/assets/img/design/concierge-icon.png'),
                  url('/assets/img/design/stars-icon.png');
          background-position: left center, right center;
          background-repeat: no-repeat, no-repeat;
          background-size: auto auto; /* Ensure images are sized properly */
          min-height: 20rem;
        }
        .tracklab-image{
          @include respond-to(small-phone){
            width: 100%;
          }
        }
        &.sweepstakes-inner{
          background-image: url(/assets/img/design/sweepstakes-gradient.png);
          background-position: right center;
          background-repeat: no-repeat;
          background-size: contain;
          min-height: 10rem;
        }
        &.uneven{
          flex-direction: row;
          column-gap: 4rem;
          align-items: center;
          justify-content: space-between;
          display: flex;
          div:first-child{
            flex: 0 1 60%;
            @include respond-to(small-phone){
              padding-bottom: 3rem;
              flex: unset;

            }
            @include respond-to(medium){
              padding-bottom: 3rem;
              flex: unset;
            }
          }
          div:last-child{
            flex: 0 1 40%;
            text-align: right;
            @include respond-to(small-phone){
              padding-bottom: 2rem;
              flex: unset;
              text-align: center;
            }
            @include respond-to(medium){
              padding-bottom: 2rem;
              flex: unset;
              text-align: center;
            }
          }
          @include respond-to(small-phone){
            flex-direction: column;
          }
          @include respond-to(medium){
            flex-direction: column;
          }
          .heading{
            margin: 0;
            font-family: AvenirNext-Bold, sans-serif;
            font-size: rem-calc(48);
            letter-spacing: -1px;
            line-height: rem-calc(58);
            text-align: unset;
          }
          p{
            padding-top: 1rem;
            font-family: AvenirNext-Medium, sans-serif;
            font-size: rem-calc(20);
            color: $color-white;
            letter-spacing: 0.3px;
            line-height: rem-calc(30);
            @include respond-to(small-phone){
              width: unset;
            }
          }
        }
      }
    }

  }

  // Global Footer
  footer{
    //background-color: red;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;

    .footer-container{
      border-top: 1px solid $color-light-gray;
      padding: 3rem 0 5rem 0;
      max-width: $maxWidth;
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      a{
        color: $color-dark;
        text-decoration: none;
      }
      @include respond-to(small-phone){
        // max-width: 40rem;
        padding: 3rem 0;
        flex-direction: column-reverse;
        text-align: center;
        div{
          padding-bottom: 1.5rem;
        }

      }
      @include respond-to(medium){
        max-width: 40rem;
        padding: 3rem 1rem;
      }
      @include respond-to(medium-large){
        //max-width: 60rem;
        padding: 3rem 0;
      }
      @include respond-to(large){
        max-width: 80rem;
        padding: 3rem 0;
      }

    }
  }


}
