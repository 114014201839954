@charset "UTF-8";
/* http://meyerweb.com/eric/tools/css/reset/
   v2.0 | 20110126
   License: none (public domain)
*/
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}

/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
  display: block;
}

body {
  line-height: 1;
}

ol, ul {
  list-style: none;
}

blockquote, q {
  quotes: none;
}

blockquote:before, blockquote:after,
q:before, q:after {
  content: "";
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

strong {
  font-weight: bold;
}

.cta-button {
  padding: 1rem 2rem;
  font-weight: 600;
  transition-property: color, background-color;
  transition-duration: 0.2s;
  transition-timing-function: ease-in-out;
  background: transparent;
  color: #fff;
  text-decoration: none;
  border: 2px solid #fff;
}
.cta-button:hover {
  background: #fff;
  color: #0671D4;
}

body {
  margin: 0;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  -webkit-font-smoothing: antialiased;
  font-size: 1rem;
  font-family: "Avenir", sans-serif;
}
body nav {
  z-index: 100;
  position: sticky;
  top: 0;
}
body nav.sticky-nav-home {
  background-color: #5c5c5b;
  transition: background-color 0.1s ease-in-out;
}
body nav .nav-inner-container {
  max-width: 80rem;
  display: flex;
  margin: 0 auto;
  padding: 2rem 0;
}
@media only screen and (min-width: 601px) and (max-width: 768px) {
  body nav .nav-inner-container {
    max-width: 50rem;
    padding-left: 1rem;
    padding-right: 1rem;
  }
}
@media only screen and (min-width: 769px) and (max-width: 950px) {
  body nav .nav-inner-container {
    max-width: 60rem;
    padding-left: 1rem;
    padding-right: 1rem;
  }
}
@media only screen and (min-width: 951px) and (max-width: 1300px) {
  body nav .nav-inner-container {
    max-width: 80rem;
    padding-left: 1rem;
    padding-right: 1rem;
  }
}
@media only screen and (max-width: 600px) {
  body nav .nav-inner-container .logo {
    padding-left: 1rem;
  }
  body nav .nav-inner-container .logo img {
    width: 75%;
  }
}
body nav menu {
  flex: 1;
  justify-content: flex-end;
  align-items: center;
  display: flex;
  list-style: none;
  color: #fff;
}
body nav menu li {
  margin-left: 1rem;
  padding: 0 1rem;
}
body nav menu li a {
  color: #fff;
  text-decoration: none;
  font-family: "AvenirNext-Bold", sans-serif;
  font-weight: 600;
  font-size: 1.25rem;
  transition-property: color;
  transition-duration: 0.3s;
  transition-timing-function: ease-in-out;
}
body nav menu li a:hover {
  color: #0671D4;
}
body nav menu li a:hover:after {
  content: "";
  display: block;
  width: 1.5rem;
  padding: 0;
  border-bottom: 2px solid #0671D4;
  transition: width 0.3s ease 0s, left 0.3s ease 0s;
}
@media only screen and (max-width: 600px) {
  body nav menu {
    display: none;
  }
}
@media only screen and (min-width: 601px) and (max-width: 768px) {
  body nav menu {
    display: none;
  }
}
body #header-home {
  display: flex;
  align-self: center;
  position: relative;
  z-index: 1;
  background-image: url("/assets/img/design/home-hero.jpg");
  background-size: cover;
  background-position: center center;
  width: 100%;
  top: -8rem;
  min-height: 34rem;
}
@media only screen and (max-width: 600px) {
  body #header-home {
    min-height: 30rem;
  }
}
body #header-home video {
  position: absolute;
  right: 0;
  bottom: 0;
  min-width: 100%;
  min-height: 100%;
  width: 100%;
  object-fit: cover;
  height: 100%;
  z-index: -100;
  background-size: cover;
  overflow: hidden;
}
body #header-home .head-container {
  max-width: 80rem;
  width: 100%;
  display: flex;
  margin: 0 auto;
  justify-content: center;
}
@media only screen and (max-width: 600px) {
  body #header-home .head-container {
    padding: 8rem 0 2rem 0;
  }
}
body #header-home .head-container h1 {
  font-size: 3.5rem;
  align-self: center;
  font-family: "AvenirNext-Bold", sans-serif;
  color: #FFFFFF;
  text-align: center;
  line-height: 72px;
  text-shadow: 4px 4px 8px rgba(0, 0, 0, 0.5);
  max-width: 80%;
}
@media only screen and (max-width: 600px) {
  body #header-home .head-container h1 {
    font-size: 2.875rem;
    line-height: 48px;
    max-width: 90%;
  }
}
body #columns {
  width: 100%;
  margin-top: -8rem;
}
body #columns .row-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 2rem 0;
}
body #columns .row-container.white-full .heading {
  width: 80%;
  font-family: AvenirNext-DemiBold, sans-serif;
  font-size: 1.5rem;
  color: #1A1A1A;
  letter-spacing: 0;
  text-align: center;
  line-height: 2.125rem;
}
@media only screen and (max-width: 600px) {
  body #columns .row-container.white-full .heading {
    width: 100%;
  }
}
body #columns .row-container.white-full .blue-cta {
  display: block;
  padding-top: 2rem;
  font-family: AvenirNext-Bold, sans-serif;
  font-size: 1.375rem;
  color: #0671D4;
  letter-spacing: 0.33px;
  text-align: center;
  line-height: 2.125rem;
  text-decoration: none;
}
body #columns .row-container.blue {
  background-color: #0671D4;
  color: #fff !important;
  font-family: AvenirNext-DemiBold, sans-serif;
  font-size: 1.25rem;
  letter-spacing: 0.3px;
  line-height: 30px;
  margin-bottom: 0;
}
body #columns .row-container.blue .subhead {
  font-family: AvenirNext-Bold, sans-serif;
  font-size: 1.5rem;
  color: #B6DBFE;
  letter-spacing: -0.5px;
  line-height: 58px;
  padding: 0;
  margin: 0 0 2rem 0;
  text-align: center;
  width: auto;
}
body #columns .row-container.blue .subhead:after {
  content: "";
  display: block;
  width: 50px;
  margin: 0 auto;
  border-bottom: 2px solid #fff;
}
@media only screen and (max-width: 600px) {
  body #columns .row-container.blue .subhead:after {
    width: auto;
  }
}
body #columns .row-container.blue .heading {
  margin: 0;
  font-family: AvenirNext-Bold, sans-serif;
  font-size: 3rem;
  letter-spacing: -1px;
  line-height: 3.625rem;
}
body #columns .row-container.blue .description {
  width: 80%;
  text-align: center;
  padding-top: 1.25rem;
}
@media only screen and (max-width: 600px) {
  body #columns .row-container.blue .description {
    width: unset;
  }
}
body #columns .row-container.sweepstakes {
  margin-top: 0.5rem;
  background-color: #51C1B6;
  color: #fff;
}
body #columns .row-container.sweepstakes .heading {
  font-family: AvenirNext-Bold, sans-serif;
  font-size: 3rem;
  letter-spacing: -1px;
  text-align: center;
  line-height: 3.625rem;
}
body #columns .row-container.sweepstakes p {
  width: 80%;
  padding-top: 2rem;
  font-family: AvenirNext-Medium, sans-serif;
  font-size: 1.25rem;
  color: #fff;
  letter-spacing: 0.3px;
  line-height: 1.875rem;
  text-align: center;
}
body #columns .row-container.teal {
  background-color: #1D8290;
  color: #fff;
  font-family: AvenirNext-DemiBold, sans-serif;
  font-size: 1.25rem;
  letter-spacing: 0.3px;
  line-height: 30px;
  margin-bottom: 0;
  min-height: 20rem;
}
body #columns .row-container.teal .heading {
  margin: 0;
  font-family: AvenirNext-Bold, sans-serif;
  font-size: 3rem;
  letter-spacing: -1px;
  line-height: 3.625rem;
}
@media only screen and (max-width: 600px) {
  body #columns .row-container.teal .heading {
    font-size: 2.25rem;
  }
}
body #columns .row-container.teal .description {
  width: 80%;
  text-align: center;
  padding-top: 1.25rem;
}
@media only screen and (max-width: 600px) {
  body #columns .row-container.teal .description {
    width: unset;
  }
}
body #columns .row-container.group-travel .heading {
  font-family: AvenirNext-Bold, sans-serif;
  font-size: 3rem;
  color: #1A1A1A;
  letter-spacing: -1px;
  text-align: center;
  line-height: 3.625rem;
  padding-bottom: 2rem;
}
body #columns .row-container.group-travel p {
  font-family: AvenirNext-Medium, sans-serif;
  font-size: 1.125rem;
  color: #1A1A1A;
  letter-spacing: 0.3px;
  text-align: center;
  line-height: 1.75rem;
  padding-bottom: 1.5rem;
  width: 80%;
}
@media only screen and (max-width: 600px) {
  body #columns .row-container.group-travel p {
    width: unset;
  }
}
body #columns .row-container.group-travel .logo-holder {
  padding: 1rem 0;
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  justify-items: unset;
  justify-content: space-evenly;
}
@media only screen and (max-width: 600px) {
  body #columns .row-container.group-travel .logo-holder {
    flex-direction: column;
  }
}
@media only screen and (min-width: 601px) and (max-width: 768px) {
  body #columns .row-container.group-travel .logo-holder img {
    max-width: 10rem;
  }
}
body #columns .row-container.benefits {
  background-color: #51C1B6;
  color: #fff;
}
body #columns .row-container.benefits .heading {
  font-family: AvenirNext-DemiBold, sans-serif;
  font-size: 1.5rem;
  letter-spacing: 0.4px;
  text-align: center;
  line-height: 2rem;
}
body #columns .row-container.benefits .icons {
  padding: 2rem 0;
  display: flex;
  width: 100%;
  justify-items: unset;
  justify-content: space-evenly;
}
@media only screen and (max-width: 600px) {
  body #columns .row-container.benefits .icons {
    flex-direction: column;
  }
  body #columns .row-container.benefits .icons img {
    padding-bottom: unset;
  }
}
body #columns .row-container.benefits .icons div {
  flex: 1;
  text-align: center;
  max-width: 25rem;
}
@media only screen and (max-width: 600px) {
  body #columns .row-container.benefits .icons div {
    max-width: unset;
    padding-bottom: 3rem;
  }
  body #columns .row-container.benefits .icons div:last-child {
    padding-bottom: unset;
  }
}
body #columns .row-container.benefits .icons div p {
  padding-top: 1.5rem;
  font-family: AvenirNext-Bold, sans-serif;
  font-size: 1.125rem;
  color: #FFFFFF;
  letter-spacing: 0;
  line-height: 1.5rem;
}
@media only screen and (max-width: 600px) {
  body #columns .row-container.benefits .icons div p {
    padding-top: unset;
  }
}
body #columns .row-container.about-us {
  background-color: #F3F3F3;
  color: #1A1A1A;
}
body #columns .row-container.about-us .heading {
  font-family: AvenirNext-Bold, sans-serif;
  font-size: 3rem;
  letter-spacing: -1px;
  line-height: 3.625rem;
}
body #columns .row-container.about-us p {
  padding-top: 1rem;
  font-family: AvenirNext-Medium, sans-serif;
  font-size: 1.25rem;
  color: #040404;
  letter-spacing: 0.3px;
  line-height: 1.875rem;
  text-align: center;
  width: 75%;
}
@media only screen and (max-width: 600px) {
  body #columns .row-container.about-us p {
    width: unset;
  }
}
body #columns .row-container.about-us .dl-leadership-grid {
  padding-top: 3rem;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 2rem;
  max-width: 80%;
}
@media only screen and (max-width: 600px) {
  body #columns .row-container.about-us .dl-leadership-grid {
    grid-template-columns: repeat(1, 1fr);
  }
}
body #columns .row-container.about-us .dl-leadership-grid div {
  font-family: AvenirNext-Bold, sans-serif;
  text-align: center;
}
body #columns .row-container.about-us .dl-leadership-grid div img {
  width: 100%;
  height: auto;
}
@media only screen and (max-width: 600px) {
  body #columns .row-container.about-us .dl-leadership-grid div img {
    padding-bottom: unset;
  }
}
body #columns .row-container.about-us .dl-leadership-grid div p {
  padding: unset;
  font-size: 1rem;
  width: unset;
  font-weight: 600;
}
body #columns .row-container.about-us .dl-leadership-grid div h3 {
  color: #07AB91;
  font-size: 1rem;
  padding-top: 1rem;
  text-transform: uppercase;
}
body #columns .row-container.teal-bg {
  background-color: #3e808e;
  color: #fff;
  font-family: AvenirNext-Bold, sans-serif;
  font-size: 1.125rem;
  letter-spacing: 0;
  text-align: center;
  line-height: 1.75rem;
}
body #columns .row-container.teal-bg p {
  width: 75%;
  padding-bottom: 1rem;
}
@media only screen and (max-width: 600px) {
  body #columns .row-container.teal-bg p {
    width: unset;
  }
}
body #columns .row-container.teal-bg .logos {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 2rem;
  width: 50%;
  padding-top: 2rem;
}
@media only screen and (max-width: 600px) {
  body #columns .row-container.teal-bg .logos {
    grid-template-columns: repeat(1, 1fr);
  }
}
body #columns .row-container.teal-bg .logos div {
  display: flex;
  background-color: #FBFBFB;
  width: 100%;
  align-items: center;
  justify-content: center;
}
body #columns .row-container.teal-bg .logos div img {
  padding: 1rem 0;
}
body #columns .row-container.contact-us h2 {
  font-family: AvenirNext-Bold, sans-serif;
  font-size: 3rem;
  color: #1A1A1A;
  letter-spacing: -1px;
  line-height: 3.625rem;
}
body #columns .row-container.contact-us p {
  padding: 1rem 0;
}
body #columns .row-container.contact-us .content ul {
  font-family: AvenirNext-Medium, sans-serif;
  font-size: 1.125rem;
  letter-spacing: 0.3px;
  padding-left: 20px;
}
body #columns .row-container.contact-us .content ul li {
  position: relative;
  color: #1A1A1A;
  padding: 0.5rem 0;
  display: flex;
  align-items: center;
}
body #columns .row-container.contact-us .content ul li:before {
  content: "•";
  margin-right: 10px;
  color: #07AB91;
  font-size: 1.5em;
}
body #columns .row-container.contact-us .cta {
  padding-top: 2rem;
}
body #columns .row-container.contact-us .cta a {
  color: #0671D4;
  text-decoration: none;
  text-transform: uppercase;
  border: 2px solid #0671D4;
  padding: 1rem 2rem;
  font-family: AvenirNext-Bold, sans-serif;
  font-size: 1.25rem;
  letter-spacing: -0.42px;
  text-align: center;
  line-height: 3.625rem;
  transition: 0.2s ease-in-out;
}
body #columns .row-container.contact-us .cta a:hover {
  background-color: #0671D4;
  color: #fff;
}
@media only screen and (max-width: 600px) {
  body #columns .row-container.contact-us .cta a {
    display: block;
    padding: 0.5rem 1rem;
  }
}
body #columns .row-container.white {
  background-color: #fff;
}
body #columns .row-container.white.orange-border {
  border: 2px solid #EF5A34;
}
body #columns .row-container.white h3 {
  text-transform: uppercase;
  color: #EF5A34;
  font-size: 1pxrem;
  font-weight: 600;
  padding-top: 2rem;
}
body #columns .row-container.white ul li {
  padding-top: 0.5rem;
  text-transform: uppercase;
}
body #columns .row-container.white p {
  padding: 1rem 0;
}
body #columns .row-container .row-inner {
  max-width: 80rem;
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 1rem 0;
  align-items: center;
}
@media only screen and (max-width: 600px) {
  body #columns .row-container .row-inner {
    max-width: unset;
    width: unset;
    padding: 0 1rem;
  }
  body #columns .row-container .row-inner img {
    padding-bottom: 2rem;
  }
  body #columns .row-container .row-inner img:last-child {
    padding-bottom: 0;
  }
}
@media only screen and (min-width: 601px) and (max-width: 768px) {
  body #columns .row-container .row-inner {
    max-width: 45rem;
  }
}
@media only screen and (min-width: 769px) and (max-width: 950px) {
  body #columns .row-container .row-inner {
    max-width: 55rem;
  }
}
@media only screen and (min-width: 951px) and (max-width: 1300px) {
  body #columns .row-container .row-inner {
    max-width: 80rem;
  }
}
body #columns .row-container .row-inner.two-up {
  display: flex;
  flex-direction: row;
  column-gap: 4rem;
  align-items: flex-start;
}
@media only screen and (max-width: 600px) {
  body #columns .row-container .row-inner.two-up {
    flex-direction: column;
  }
}
@media only screen and (min-width: 601px) and (max-width: 768px) {
  body #columns .row-container .row-inner.two-up {
    flex-direction: column;
  }
  body #columns .row-container .row-inner.two-up img {
    padding: 2rem 0;
    margin: 0 auto;
  }
}
@media only screen and (min-width: 769px) and (max-width: 950px) {
  body #columns .row-container .row-inner.two-up {
    flex-direction: column;
  }
  body #columns .row-container .row-inner.two-up img {
    margin: 0 auto;
  }
}
body #columns .row-container .row-inner.two-up .heading {
  font-family: AvenirNext-Bold, sans-serif;
  font-size: 3rem;
  color: #1A1A1A;
  letter-spacing: -1px;
  line-height: 3.625rem;
}
body #columns .row-container .row-inner.two-up p {
  padding-top: 1rem;
  font-family: AvenirNext-Medium, sans-serif;
  font-size: 1.25rem;
  color: #040404;
  letter-spacing: 0.3px;
  line-height: 1.875rem;
}
body #columns .row-container .row-inner.two-up.reverse {
  flex-direction: row-reverse;
}
@media only screen and (max-width: 600px) {
  body #columns .row-container .row-inner.two-up.reverse {
    flex-direction: column;
  }
}
@media only screen and (min-width: 601px) and (max-width: 768px) {
  body #columns .row-container .row-inner.two-up.reverse {
    flex-direction: column;
  }
}
@media only screen and (min-width: 769px) and (max-width: 950px) {
  body #columns .row-container .row-inner.two-up.reverse {
    flex-direction: column;
  }
}
body #columns .row-container .row-inner.two-up.reverse.white-text .heading {
  color: #fff;
}
body #columns .row-container .row-inner.two-up.reverse.white-text p {
  color: #fff;
}
@media only screen and (min-width: 769px) and (max-width: 950px) {
  body #columns .row-container .row-inner.two-up.reverse.contact-exception {
    flex-direction: row-reverse !important;
  }
}
body #columns .row-container .row-inner.two-up div {
  flex: 0 1 50%;
}
body #columns .row-container .row-inner.two-up div.carousel {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
}
@media only screen and (max-width: 600px) {
  body #columns .row-container .row-inner.two-up div.carousel {
    padding: 2rem 0;
    width: 100%;
  }
}
@media only screen and (min-width: 601px) and (max-width: 768px) {
  body #columns .row-container .row-inner.two-up div.carousel {
    padding: 2rem 0;
    width: 100%;
  }
}
@media only screen and (min-width: 769px) and (max-width: 950px) {
  body #columns .row-container .row-inner.two-up div.carousel {
    padding: 2rem 0;
    width: 100%;
  }
}
@media only screen and (min-width: 951px) and (max-width: 1300px) {
  body #columns .row-container .row-inner.two-up div.carousel {
    padding: 2rem 0;
  }
}
body #columns .row-container .row-inner.blue-inner {
  background-image: url("/assets/img/design/wwd-location.png"), url("/assets/img/design/wwd-contract.png");
  background-position: left center, right bottom;
  background-repeat: no-repeat, no-repeat;
  background-size: auto auto; /* Ensure images are sized properly */
  min-height: 20rem;
}
body #columns .row-container .row-inner.teal-inner {
  width: 80%;
  justify-content: center;
  background-image: url("/assets/img/design/concierge-icon.png"), url("/assets/img/design/stars-icon.png");
  background-position: left center, right center;
  background-repeat: no-repeat, no-repeat;
  background-size: auto auto; /* Ensure images are sized properly */
  min-height: 20rem;
}
@media only screen and (max-width: 600px) {
  body #columns .row-container .row-inner .tracklab-image {
    width: 100%;
  }
}
body #columns .row-container .row-inner.sweepstakes-inner {
  background-image: url(/assets/img/design/sweepstakes-gradient.png);
  background-position: right center;
  background-repeat: no-repeat;
  background-size: contain;
  min-height: 10rem;
}
body #columns .row-container .row-inner.uneven {
  flex-direction: row;
  column-gap: 4rem;
  align-items: center;
  justify-content: space-between;
  display: flex;
}
body #columns .row-container .row-inner.uneven div:first-child {
  flex: 0 1 60%;
}
@media only screen and (max-width: 600px) {
  body #columns .row-container .row-inner.uneven div:first-child {
    padding-bottom: 3rem;
    flex: unset;
  }
}
@media only screen and (min-width: 601px) and (max-width: 768px) {
  body #columns .row-container .row-inner.uneven div:first-child {
    padding-bottom: 3rem;
    flex: unset;
  }
}
body #columns .row-container .row-inner.uneven div:last-child {
  flex: 0 1 40%;
  text-align: right;
}
@media only screen and (max-width: 600px) {
  body #columns .row-container .row-inner.uneven div:last-child {
    padding-bottom: 2rem;
    flex: unset;
    text-align: center;
  }
}
@media only screen and (min-width: 601px) and (max-width: 768px) {
  body #columns .row-container .row-inner.uneven div:last-child {
    padding-bottom: 2rem;
    flex: unset;
    text-align: center;
  }
}
@media only screen and (max-width: 600px) {
  body #columns .row-container .row-inner.uneven {
    flex-direction: column;
  }
}
@media only screen and (min-width: 601px) and (max-width: 768px) {
  body #columns .row-container .row-inner.uneven {
    flex-direction: column;
  }
}
body #columns .row-container .row-inner.uneven .heading {
  margin: 0;
  font-family: AvenirNext-Bold, sans-serif;
  font-size: 3rem;
  letter-spacing: -1px;
  line-height: 3.625rem;
  text-align: unset;
}
body #columns .row-container .row-inner.uneven p {
  padding-top: 1rem;
  font-family: AvenirNext-Medium, sans-serif;
  font-size: 1.25rem;
  color: #fff;
  letter-spacing: 0.3px;
  line-height: 1.875rem;
}
@media only screen and (max-width: 600px) {
  body #columns .row-container .row-inner.uneven p {
    width: unset;
  }
}
body footer {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}
body footer .footer-container {
  border-top: 1px solid #E0E0E0;
  padding: 3rem 0 5rem 0;
  max-width: 80rem;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
body footer .footer-container a {
  color: #1A1A1A;
  text-decoration: none;
}
@media only screen and (max-width: 600px) {
  body footer .footer-container {
    padding: 3rem 0;
    flex-direction: column-reverse;
    text-align: center;
  }
  body footer .footer-container div {
    padding-bottom: 1.5rem;
  }
}
@media only screen and (min-width: 601px) and (max-width: 768px) {
  body footer .footer-container {
    max-width: 40rem;
    padding: 3rem 1rem;
  }
}
@media only screen and (min-width: 769px) and (max-width: 950px) {
  body footer .footer-container {
    padding: 3rem 0;
  }
}
@media only screen and (min-width: 951px) and (max-width: 1300px) {
  body footer .footer-container {
    max-width: 80rem;
    padding: 3rem 0;
  }
}

.mobile-dropdown-menu-container {
  display: none;
  overflow: hidden;
  transition: height 0.5s ease;
}
.mobile-dropdown-menu-container.is-visible {
  display: block;
  height: auto;
}

.mobile-dropdown-menu {
  width: 100%;
  background-color: #0671D4;
  z-index: 9999;
  position: absolute;
  top: 7rem;
  right: 0px;
}
.mobile-dropdown-menu > ul {
  list-style: none;
  margin: 0;
  padding: 0;
}
.mobile-dropdown-menu > ul > li {
  text-align: left;
  padding: 0;
  font-weight: 500;
  display: block;
  cursor: pointer !important;
  font-size: 0.95rem;
  position: relative;
}
.mobile-dropdown-menu > ul > li a, .mobile-dropdown-menu > ul > li span {
  width: 100%;
  color: #fff;
  padding: 0.75rem 0 0.75rem 0.625rem;
  display: block;
  transition: 0.2s;
  font-weight: #07AB91;
  text-decoration: none;
}
.mobile-dropdown-menu > ul > li a:hover, .mobile-dropdown-menu > ul > li span:hover {
  background-color: #0669c5;
}

ul.inner-mobile-dropdown {
  display: none;
  list-style-type: none;
  margin: 0;
}
ul.inner-mobile-dropdown > li {
  text-align: left;
  padding: 0;
  display: block;
  cursor: pointer !important;
  font-size: 0.875pxrem;
}
ul.inner-mobile-dropdown > li a, ul.inner-mobile-dropdown > li span {
  padding: 0.5rem 0 0.5rem 0.625rem;
  background-color: #f16d4c;
}
ul.inner-mobile-dropdown > li a:hover {
  background-color: #f06642;
}
ul.inner-mobile-dropdown > li span {
  font-weight: 600;
}
ul.inner-mobile-dropdown > li span:hover {
  background-color: #f16d4c;
}
ul.inner-mobile-dropdown > li ul.menu {
  display: block;
}
ul.inner-mobile-dropdown > li ul.menu li {
  padding: 0;
}
ul.inner-mobile-dropdown > li ul.menu li a {
  padding: 0.5rem 0 0.5rem 0.625rem;
}
ul.inner-mobile-dropdown > li strong {
  background-color: #f16d4c;
  padding: 0.5rem 0 0.5rem 0.625rem;
  width: 100%;
  color: #e7e7e7;
  display: block;
}

.mm-inactive {
  background-color: #EF5A34;
}
.mm-inactive:after {
  content: "\f054";
  position: absolute;
  right: 1.6rem;
  top: 0.6rem;
  font-size: 1.1rem;
  color: #e7e7e7;
}

.mm-active > span {
  background-color: #EF5A34;
}
.mm-active:after {
  content: "\f078";
  position: absolute;
  right: 1.6rem;
  top: 0.6rem;
  font-size: 1.1rem;
  color: #e7e7e7;
}

.mobile-dropdown-button {
  position: absolute;
  right: 1.25rem;
  display: none;
  padding-top: 1rem;
  color: #fff;
  font-size: 1.6em;
  cursor: pointer;
  z-index: 99;
}
@media only screen and (max-width: 600px) {
  .mobile-dropdown-button {
    display: block;
  }
}
@media only screen and (min-width: 601px) and (max-width: 768px) {
  .mobile-dropdown-button {
    display: block;
  }
}
.mobile-dropdown-button:hover #hamburger span {
  background: #FEDF3F;
}

#hamburger {
  height: 24px;
  width: 24px;
  transform: rotate(0deg);
  transition: 0.5s ease-in-out;
  cursor: pointer;
}
#hamburger span {
  display: block;
  position: absolute;
  height: 3px;
  width: 100%;
  background: #fff;
  border-radius: 9px;
  opacity: 1;
  left: 0;
  transform: rotate(0deg);
  transition: 0.25s ease-in-out;
}

#hamburger span:nth-child(1) {
  top: 0px;
}

#hamburger span:nth-child(2), #hamburger span:nth-child(3) {
  top: 8px;
}

#hamburger span:nth-child(4) {
  top: 16px;
}

#hamburger.open span:nth-child(1) {
  top: 18px;
  width: 0%;
  left: 50%;
}

#hamburger.open span:nth-child(2) {
  transform: rotate(45deg);
}

#hamburger.open span:nth-child(3) {
  transform: rotate(-45deg);
}

#hamburger.open span:nth-child(4) {
  top: 18px;
  width: 0%;
  left: 50%;
}

.rotator-navigation {
  position: absolute;
  top: 40%;
  transform: translateY(-40%);
  z-index: 10;
  display: flex;
  width: 105%;
  justify-content: space-between;
  align-items: center;
}
@media only screen and (min-width: 769px) and (max-width: 950px) {
  .rotator-navigation {
    width: 65%;
  }
}
.rotator-navigation .prev-arrow, .rotator-navigation .next-arrow {
  cursor: pointer;
  width: 0;
  height: 0;
  background: transparent;
  transition: color 0.2s ease-in-out;
  font-size: 3rem;
  width: 2rem;
  height: 2rem;
  text-align: center;
  z-index: 25;
  transform: scaleX(0.4);
}
.rotator-navigation .prev-arrow.gray, .rotator-navigation .next-arrow.gray {
  color: #0671D4;
}
.rotator-navigation .prev-arrow.gray:hover, .rotator-navigation .next-arrow.gray:hover {
  color: #EF5A34;
}
.rotator-navigation .prev-arrow.white, .rotator-navigation .next-arrow.white {
  color: #fff;
}
.rotator-navigation .prev-arrow.white:hover, .rotator-navigation .next-arrow.white:hover {
  color: #07AB91;
}
.rotator-navigation .prev-arrow.teal, .rotator-navigation .next-arrow.teal {
  color: #07AB91;
}
.rotator-navigation .prev-arrow.teal:hover, .rotator-navigation .next-arrow.teal:hover {
  color: #E0E0E0;
}
.rotator-navigation .prev-arrow {
  left: 0;
}
.rotator-navigation .next-arrow {
  right: 0;
}

.registration-carousel {
  max-width: 35rem;
}
.registration-carousel .slide img {
  width: 100%;
  height: auto;
}
.registration-carousel .slide p {
  padding-top: 1rem;
  font-weight: 600;
  text-align: center;
  color: #0671D4;
  text-transform: uppercase;
}
@media only screen and (max-width: 600px) {
  .registration-carousel {
    max-width: 20rem;
  }
}
@media only screen and (min-width: 601px) and (max-width: 768px) {
  .registration-carousel {
    max-width: 35rem;
  }
}
@media only screen and (min-width: 769px) and (max-width: 950px) {
  .registration-carousel {
    max-width: 25rem;
  }
}

.registration-dots ul.slick-dots {
  display: flex;
  justify-content: center;
  padding: 0;
}
.registration-dots ul.slick-dots li button {
  background-color: inherit;
  border: 1px solid #E0E0E0;
  border-radius: 50%;
  width: 10px;
  height: 10px;
  margin: 0 5px;
  cursor: pointer;
  padding: 0;
  font-size: 0;
}
.registration-dots ul.slick-dots li button:focus {
  outline: none;
}
.registration-dots ul.slick-dots li.slick-active button {
  background-color: #07AB91;
  border-color: #07AB91;
}

.tracklab-carousel {
  max-width: 35rem;
}
.tracklab-carousel a {
  position: relative;
  color: #fff;
  text-transform: uppercase;
  display: block;
  padding-bottom: 0.5rem;
  font-size: 0.75rem;
  font-weight: 600;
  text-decoration: none;
  text-align: right;
}
.tracklab-carousel a::after {
  font-family: unset;
  content: "→";
  top: 0;
  transform: rotate(45deg);
  transform-origin: center;
}
@media only screen and (max-width: 600px) {
  .tracklab-carousel a {
    text-align: right;
  }
}
@media only screen and (max-width: 600px) {
  .tracklab-carousel {
    max-width: 20rem;
  }
}
@media only screen and (min-width: 601px) and (max-width: 768px) {
  .tracklab-carousel {
    max-width: 35rem;
  }
}
@media only screen and (min-width: 769px) and (max-width: 950px) {
  .tracklab-carousel {
    max-width: 25rem;
  }
}

.tracklab-dots ul.slick-dots {
  display: flex;
  justify-content: center;
  padding: 0;
}
.tracklab-dots ul.slick-dots li button {
  background-color: inherit;
  border: 1px solid #fff;
  border-radius: 50%;
  width: 10px;
  height: 10px;
  margin: 0 5px;
  cursor: pointer;
  padding: 0;
  font-size: 0;
}
.tracklab-dots ul.slick-dots li button:focus {
  outline: none;
}
.tracklab-dots ul.slick-dots li.slick-active button {
  background-color: #fff;
  border-color: #fff;
}

.inspiration-carousel a, .registration-carousel a {
  position: relative;
  color: #07AB91;
  text-transform: uppercase;
  display: block;
  padding-bottom: 0.5rem;
  font-size: 0.75rem;
  font-weight: 600;
  text-decoration: none;
  text-align: right;
}
.inspiration-carousel a::after, .registration-carousel a::after {
  font-family: unset;
  content: "→";
  top: 0;
  transform: rotate(45deg);
  transform-origin: center;
}
@media only screen and (max-width: 600px) {
  .inspiration-carousel a, .registration-carousel a {
    text-align: right;
  }
}

.inspiration-carousel {
  max-width: 35rem;
}
.inspiration-carousel .slide img {
  width: 100%;
  height: auto;
}
.inspiration-carousel .slide p {
  padding-top: 1rem;
  font-weight: 600;
  text-align: center;
  color: #0671D4;
  text-transform: uppercase;
}
@media only screen and (max-width: 600px) {
  .inspiration-carousel {
    max-width: 20rem;
  }
}
@media only screen and (min-width: 601px) and (max-width: 768px) {
  .inspiration-carousel {
    max-width: 35rem;
  }
}
@media only screen and (min-width: 769px) and (max-width: 950px) {
  .inspiration-carousel {
    max-width: 25rem;
  }
}

.inspiration-dots ul.slick-dots {
  display: flex;
  justify-content: center;
  padding: 0;
}
.inspiration-dots ul.slick-dots li button {
  background-color: inherit;
  border: 1px solid #E0E0E0;
  border-radius: 50%;
  width: 10px;
  height: 10px;
  margin: 0 5px;
  cursor: pointer;
  padding: 0;
  font-size: 0;
}
.inspiration-dots ul.slick-dots li button:focus {
  outline: none;
}
.inspiration-dots ul.slick-dots li.slick-active button {
  background-color: #07AB91;
  border-color: #07AB91;
}

/* Slider */
.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;
}

.slick-list {
  position: relative;
  overflow: hidden;
  display: block;
  margin: 0;
  padding: 0;
}
.slick-list:focus {
  outline: none;
}
.slick-list.dragging {
  cursor: pointer;
  cursor: hand;
}

.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.slick-track {
  position: relative;
  left: 0;
  top: 0;
  display: block;
  margin-left: auto;
  margin-right: auto;
}
.slick-track:before, .slick-track:after {
  content: "";
  display: table;
}
.slick-track:after {
  clear: both;
}
.slick-loading .slick-track {
  visibility: hidden;
}

.slick-slide {
  float: left;
  height: 100%;
  min-height: 1px;
  display: none;
}
[dir=rtl] .slick-slide {
  float: right;
}
.slick-slide img {
  display: block;
}
.slick-slide.slick-loading img {
  display: none;
}
.slick-slide.dragging img {
  pointer-events: none;
}
.slick-initialized .slick-slide {
  display: block;
}
.slick-loading .slick-slide {
  visibility: hidden;
}
.slick-vertical .slick-slide {
  display: block;
  height: auto;
  border: 1px solid transparent;
}

.slick-arrow.slick-hidden {
  display: none;
}

/* Magnific Popup CSS */
.mfp-bg {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1042;
  overflow: hidden;
  position: fixed;
  background: #0b0b0b;
  opacity: 0.8;
}

.mfp-wrap {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1043;
  position: fixed;
  outline: none !important;
  -webkit-backface-visibility: hidden;
}

.mfp-container {
  text-align: center;
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  padding: 0 8px;
  box-sizing: border-box;
}

.mfp-container:before {
  content: "";
  display: inline-block;
  height: 100%;
  vertical-align: middle;
}

.mfp-align-top .mfp-container:before {
  display: none;
}

.mfp-content {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  margin: 0 auto;
  text-align: left;
  z-index: 1045;
}

.mfp-inline-holder .mfp-content,
.mfp-ajax-holder .mfp-content {
  width: 100%;
  cursor: auto;
}

.mfp-ajax-cur {
  cursor: progress;
}

.mfp-zoom-out-cur, .mfp-zoom-out-cur .mfp-image-holder .mfp-close {
  cursor: -moz-zoom-out;
  cursor: -webkit-zoom-out;
  cursor: zoom-out;
}

.mfp-zoom {
  cursor: pointer;
  cursor: -webkit-zoom-in;
  cursor: -moz-zoom-in;
  cursor: zoom-in;
}

.mfp-auto-cursor .mfp-content {
  cursor: auto;
}

.mfp-close,
.mfp-arrow,
.mfp-preloader,
.mfp-counter {
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
}

.mfp-loading.mfp-figure {
  display: none;
}

.mfp-hide {
  display: none !important;
}

.mfp-preloader {
  color: #CCC;
  position: absolute;
  top: 50%;
  width: auto;
  text-align: center;
  margin-top: -0.8em;
  left: 8px;
  right: 8px;
  z-index: 1044;
}

.mfp-preloader a {
  color: #CCC;
}

.mfp-preloader a:hover {
  color: #FFF;
}

.mfp-s-ready .mfp-preloader {
  display: none;
}

.mfp-s-error .mfp-content {
  display: none;
}

button.mfp-close,
button.mfp-arrow {
  overflow: visible;
  cursor: pointer;
  background: transparent;
  border: 0;
  -webkit-appearance: none;
  display: block;
  outline: none;
  padding: 0;
  z-index: 1046;
  box-shadow: none;
  touch-action: manipulation;
}

button::-moz-focus-inner {
  padding: 0;
  border: 0;
}

.mfp-close {
  width: 44px;
  height: 44px;
  line-height: 44px;
  position: absolute;
  right: 0;
  top: 0;
  text-decoration: none;
  text-align: center;
  opacity: 0.65;
  padding: 0 0 18px 10px;
  color: #FFF;
  font-style: normal;
  font-size: 28px;
  font-family: Arial, Baskerville, monospace;
}

.mfp-close:hover,
.mfp-close:focus {
  opacity: 1;
}

.mfp-close:active {
  top: 1px;
}

.mfp-close-btn-in .mfp-close {
  color: #333;
}

.mfp-image-holder .mfp-close,
.mfp-iframe-holder .mfp-close {
  color: #FFF;
  right: -6px;
  text-align: right;
  padding-right: 6px;
  width: 100%;
}

.mfp-counter {
  position: absolute;
  top: 0;
  right: 0;
  color: #CCC;
  font-size: 12px;
  line-height: 18px;
  white-space: nowrap;
}

.mfp-arrow {
  position: absolute;
  opacity: 0.65;
  margin: 0;
  top: 50%;
  margin-top: -55px;
  padding: 0;
  width: 90px;
  height: 110px;
  -webkit-tap-highlight-color: transparent;
}

.mfp-arrow:active {
  margin-top: -54px;
}

.mfp-arrow:hover,
.mfp-arrow:focus {
  opacity: 1;
}

.mfp-arrow:before,
.mfp-arrow:after {
  content: "";
  display: block;
  width: 0;
  height: 0;
  position: absolute;
  left: 0;
  top: 0;
  margin-top: 35px;
  margin-left: 35px;
  border: medium inset transparent;
}

.mfp-arrow:after {
  border-top-width: 13px;
  border-bottom-width: 13px;
  top: 8px;
}

.mfp-arrow:before {
  border-top-width: 21px;
  border-bottom-width: 21px;
  opacity: 0.7;
}

.mfp-arrow-left {
  left: 0;
}

.mfp-arrow-left:after {
  border-right: 17px solid #FFF;
  margin-left: 31px;
}

.mfp-arrow-left:before {
  margin-left: 25px;
  border-right: 27px solid #3F3F3F;
}

.mfp-arrow-right {
  right: 0;
}

.mfp-arrow-right:after {
  border-left: 17px solid #FFF;
  margin-left: 39px;
}

.mfp-arrow-right:before {
  border-left: 27px solid #3F3F3F;
}

.mfp-iframe-holder {
  padding-top: 40px;
  padding-bottom: 40px;
}

.mfp-iframe-holder .mfp-content {
  line-height: 0;
  width: 100%;
  max-width: 900px;
}

.mfp-iframe-holder .mfp-close {
  top: -40px;
}

.mfp-iframe-scaler {
  width: 100%;
  height: 0;
  overflow: hidden;
  padding-top: 56.25%;
}

.mfp-iframe-scaler iframe {
  position: absolute;
  display: block;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.6);
  background: #000;
}

/* Main image in popup */
img.mfp-img {
  width: auto;
  max-width: 100%;
  height: auto;
  display: block;
  line-height: 0;
  box-sizing: border-box;
  padding: 40px 0 40px;
  margin: 0 auto;
}

/* The shadow behind the image */
.mfp-figure {
  line-height: 0;
}

.mfp-figure:after {
  content: "";
  position: absolute;
  left: 0;
  top: 40px;
  bottom: 40px;
  display: block;
  right: 0;
  width: auto;
  height: auto;
  z-index: -1;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.6);
  background: #444;
}

.mfp-figure small {
  color: #BDBDBD;
  display: block;
  font-size: 12px;
  line-height: 14px;
}

.mfp-figure figure {
  margin: 0;
}

.mfp-bottom-bar {
  margin-top: -36px;
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  cursor: auto;
}

.mfp-title {
  text-align: left;
  line-height: 18px;
  color: #F3F3F3;
  word-wrap: break-word;
  padding-right: 36px;
}

.mfp-image-holder .mfp-content {
  max-width: 100%;
}

.mfp-gallery .mfp-image-holder .mfp-figure {
  cursor: pointer;
}

@media screen and (max-width: 800px) and (orientation: landscape), screen and (max-height: 300px) {
  /**
       * Remove all paddings around the image on small screen
       */
  .mfp-img-mobile .mfp-image-holder {
    padding-left: 0;
    padding-right: 0;
  }
  .mfp-img-mobile img.mfp-img {
    padding: 0;
  }
  .mfp-img-mobile .mfp-figure:after {
    top: 0;
    bottom: 0;
  }
  .mfp-img-mobile .mfp-figure small {
    display: inline;
    margin-left: 5px;
  }
  .mfp-img-mobile .mfp-bottom-bar {
    background: rgba(0, 0, 0, 0.6);
    bottom: 0;
    margin: 0;
    top: auto;
    padding: 3px 5px;
    position: fixed;
    box-sizing: border-box;
  }
  .mfp-img-mobile .mfp-bottom-bar:empty {
    padding: 0;
  }
  .mfp-img-mobile .mfp-counter {
    right: 5px;
    top: 3px;
  }
  .mfp-img-mobile .mfp-close {
    top: 0;
    right: 0;
    width: 35px;
    height: 35px;
    line-height: 35px;
    background: rgba(0, 0, 0, 0.6);
    position: fixed;
    text-align: center;
    padding: 0;
  }
}
@media all and (max-width: 900px) {
  .mfp-arrow {
    -webkit-transform: scale(0.75);
    transform: scale(0.75);
  }
  .mfp-arrow-left {
    -webkit-transform-origin: 0;
    transform-origin: 0;
  }
  .mfp-arrow-right {
    -webkit-transform-origin: 100%;
    transform-origin: 100%;
  }
  .mfp-container {
    padding-left: 6px;
    padding-right: 6px;
  }
}
@media print {
  .hide-for-print {
    display: none;
  }
}