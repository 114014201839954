$body-font-family: 'Avenir', sans-serif;
$base-font-size: 1rem;

//Colors
$color-white: #fff;
$color-link-highlight: #FEDF3F;
$color-orange: #EF5A34;
$color-dark-blue: #0671D4;
$color-dark: #1A1A1A;
$color-teal: #07AB91;
$color-light-gray: #E0E0E0;


strong{
  font-weight: bold;
}

.cta-button{
  padding: 1rem 2rem;
  font-weight: 600;
  transition-property: color, background-color;
  transition-duration: .2s;
  transition-timing-function: ease-in-out;
  background: transparent;
  color: $color-white;
  text-decoration: none;
  border: 2px solid $color-white;

  &:hover{
    background: $color-white;
    color: $color-dark-blue;
    //color: $color-dark;
  }
}