@charset "utf-8";
//mixins
@import 'mixins';


@import 'reset';
@import 'typography';
@import 'layout';
@import 'mobile-nav';
@import 'components';
@import 'slick';
@import 'magnific';

@media print{
  .hide-for-print{
    display: none;
  }
}
