// Mobile Menu
// ===============================================================

// Mobile Header

.mobile-dropdown-menu-container {
  display: none;
  overflow: hidden;
  transition: height 0.5s ease;
  &.is-visible {
    display: block;
    height: auto;
  }
}

// Mobile Dropdown Menu

.mobile-dropdown-menu {

  // display: none;
  width: 100%;
  background-color: $color-dark-blue;
  z-index: 9999;
  position: absolute;
  top: 7rem;
  right: 0px;

  & > ul {
    list-style: none;
    margin: 0;
    padding: 0;

    & > li {
      text-align: left;
      padding: 0;
      font-weight: 500;
      display: block;
      cursor: pointer !important;
      font-size: .95rem;
      position: relative;

      a, span {
        width: 100%;
        color: $color-white;
        padding: .75rem 0 .75rem 0.625rem;
        display: block;
        transition: .2s;
        font-weight: $color-teal;
        text-decoration: none;

        &:hover {
          background-color: darken($color-dark-blue, 3%);
        }
      }
    }
  }
}

// Inner Mobile Dropdown Menu

ul.inner-mobile-dropdown {
  display: none;
  list-style-type: none;
  margin: 0;

  & > li {
    text-align: left;
    padding: 0;
    display: block;
    cursor: pointer !important;
    font-size: rem-calc(14px);

    a, span {
      padding: .5rem 0 .5rem 0.625rem;
      background-color: lighten($color-orange, 5%);
    }

    a {
      // font-weight: $regular;

      &:hover {
        background-color: lighten($color-orange, 3%);
      }
    }

    span {
      font-weight: 600;

      &:hover {
        background-color: lighten($color-orange, 5%);
      }
    }

    ul.menu {
      display: block;

      li {
        padding: 0;

        a {
          padding: .5rem 0 .5rem 0.625rem;
        }
      }
    }

    strong {
      background-color: lighten($color-orange, 5%);
      padding: .5rem 0 .5rem 0.625rem;
      width: 100%;
      color: #e7e7e7;
      display: block;
    }
  }
}

.mm-inactive {
  background-color: $color-orange;

  &:after {
    //font-family: "Font Awesome 5 Free";
    content: "\f054";
    position: absolute;
    right: 1.6rem;
    top: .6rem;
    font-size: 1.1rem;
    color: #e7e7e7;
  }
}
.mm-active { // If this element's submenu is open

  & > span {
    background-color: $color-orange;
  }

  &:after {
    //font-family: "Font Awesome 5 Free";
    content: "\f078";
    position: absolute;
    right: 1.6rem;
    top: .6rem;
    font-size: 1.1rem;
    color: #e7e7e7;
  }
}

// The link for the hamburger icon

.mobile-dropdown-button {
  position: absolute;
  right: 1.25rem;
  display: none;
  padding-top: 1rem;
  color: $color-white;
  font-size: 1.6em;
  cursor: pointer;
  z-index: 99;

  @include respond-to(small-phone) {
    display: block;
  }
  @include respond-to(medium) {
    display: block;
  }



  &:hover {
    #hamburger {
      span {
        background: $color-link-highlight;
      }
    }
  }
}

// Animated hamburger icon

#hamburger {
  height: 24px;
  width: 24px;
  transform: rotate(0deg);
  transition: .5s ease-in-out;
  cursor: pointer;

  span {
    display: block;
    position: absolute;
    height: 3px;
    width: 100%;
    background: $color-white;
    border-radius: 9px;
    opacity: 1;
    left: 0;
    transform: rotate(0deg);
    transition: .25s ease-in-out;
  }
}

#hamburger span:nth-child(1) {
  top: 0px;
}

#hamburger span:nth-child(2),#hamburger span:nth-child(3) {
  top: 8px;
}

#hamburger span:nth-child(4) {
  top: 16px;
}

#hamburger.open span:nth-child(1) {
  top: 18px;
  width: 0%;
  left: 50%;
}

#hamburger.open span:nth-child(2) {
  transform: rotate(45deg);
}

#hamburger.open span:nth-child(3) {
  transform: rotate(-45deg);
}

#hamburger.open span:nth-child(4) {
  top: 18px;
  width: 0%;
  left: 50%;
}